import React from "react"

import useReduceMotion from "../../hooks/use-reduce-motion"

import { classnames } from "../../utils/helpers"

import skeleton from "./skeleton.module.scss"

const Skeleton = () => {
  const [rmState] = useReduceMotion()

  return (
    <div
      className={classnames([
        skeleton.Skeleton,
        rmState === 1 && skeleton["is-reduced-motion"],
      ])}
    >
      <div className={skeleton.Skeleton__image}></div>
      <div className={skeleton.Skeleton__date}>
        <div className={skeleton.Skeleton__animate}></div>
      </div>
      <div className={skeleton.Skeleton__title}>
        <div className={skeleton.Skeleton__animate}></div>
      </div>
      <div className={skeleton.Skeleton__blurb}>
        <div className={skeleton.Skeleton__animate}></div>
      </div>
      <div className={skeleton.Skeleton__blurb}>
        <div className={skeleton.Skeleton__animate}></div>
      </div>
    </div>
  )
}

export default Skeleton
