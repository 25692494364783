// TODO: Remove load more button if there are less results than the desired amount
import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import { classnames } from "../../utils/helpers"
import MediaCard from "../media-card/media-card"
import Button from "../button/button"

import useLocale from "../../hooks/use-locale"
import useBreakpoints from "../../hooks/use-breakpoints"
import useTracking from "../../hooks/use-tracking"

import { NEWS_LOCALE_MAP } from "../../utils/consts"

import styles from "./media-grid.module.scss"

const newsArticlesQuery = gql`
  query NewsArticlesForMarioPortal(
    $skip: Int!
    $limit: Int!
    $locale: Locale!
  ) {
    collection: newsArticles(
      locale: $locale
      skip: $skip
      limit: $limit
      where: { tags: { all: ["syndicationMarioPortal"] } }
      sort: [publishDate_DESC, priority_DESC]
    ) {
      total
      skip
      items {
        id
        locale
        title
        publishDate
        media {
          url(
            transforms: [{ resize: { width: "307", type: PAD }, dpr: "auto" }]
          )
        }
        slug
      }
    }
  }
`

const MediaGrid = forwardRef(function MediaGrid(
  {
    className,
    placeholders,
    itemRequestLimit,
    loadMore,
    loadLabel,
    beforeList,
    afterList,
    limitMobile,
    trackPrefix,
    ...props
  },
  ref
) {
  const { trackLink } = useTracking()
  const currentBP = useBreakpoints()
  const [skip, setSkip] = React.useState(0)
  const [totalItems, setTotalItems] = React.useState(0)
  const [articles, setArticles] = React.useState(null)
  const { globalLocale, langKey } = useLocale()
  const focusRef = React.useRef()
  const [newItemIndex, setNewItemIndex] = React.useState(0)
  const [shouldFocus, setShouldFocus] = React.useState(false)

  const { loading, error, data } = useQuery(newsArticlesQuery, {
    variables: {
      limit: itemRequestLimit,
      skip,
      locale: NEWS_LOCALE_MAP[langKey],
    },
  })

  // sets the total items, as received by the API
  React.useEffect(() => {
    const collection = data?.collection
    if (collection?.skip === skip) {
      setArticles(articles =>
        skip ? articles.concat(collection.items) : collection.items
      )
      setTotalItems(collection.total)
    }
  }, [data, skip])

  React.useEffect(() => {
    if (newItemIndex) {
      setShouldFocus(true)
    }
  }, [newItemIndex])

  React.useEffect(() => {
    if (shouldFocus && focusRef.current) {
      focusRef.current.focus()
    }
  }, [shouldFocus, articles])

  if (error) {
    return (
      <p className="wrapper txt-center margin-top-by3 margin-bottom-by3 txt-weight-bold">
        {globalLocale.newsError}
      </p>
    )
  }

  return (
    <>
      {beforeList}
      <ul
        {...props}
        className={classnames([
          "row",
          styles.MediaGrid,
          className,
          "vp-animate",
        ])}
      >
        {loading && !articles && placeholders
          ? Array(placeholders).map((_, i) => (
              <li
                className={classnames([
                  "column",
                  "column--6",
                  "column-large--4",
                  "vp-slide",
                  "vp-slide--down",
                  `vp-delay-${i}`,
                  styles.MediaGrid__item,
                ])}
                key={`media-card-${i}`}
              >
                <MediaCard hasData={false} />
              </li>
            ))
          : articles?.map((article, i) => (
              <li
                {...(limitMobile && currentBP < 3 && i === 2
                  ? { style: { display: "none" } }
                  : null)}
                className={classnames([
                  "column",
                  "column--6",
                  "column-large--4",
                  "vp-slide",
                  "vp-slide--down",
                  `vp-delay-${i}`,
                  styles.MediaGrid__item,
                ])}
                key={article.id}
              >
                <MediaCard
                  {...(newItemIndex === i ? { ref: focusRef } : null)}
                  hasData={true}
                  date={article.publishDate}
                  href={`/${langKey !== "en" ? langKey + "/" : ""}news/${
                    article.slug
                  }`}
                  image={article.media?.url}
                  title={article.title}
                  slug={article.slug}
                  trackPrefix={trackPrefix}
                />
              </li>
            ))}
      </ul>
      {articles?.length < totalItems && loadMore && (
        <div className="txt-center margin-top-by4">
          <Button
            arrow={true}
            onClick={event => {
              setSkip(value => value + itemRequestLimit)
              trackLink(
                {
                  eVars: {
                    39: `${trackPrefix ? trackPrefix + ": " : ""}see more news`,
                  },
                  events: [53],
                },
                event
              )
            }}
          >
            {loadLabel}
          </Button>
        </div>
      )}
      {afterList}
    </>
  )
})

MediaGrid.defaultProps = {
  placeholders: 9,
  itemRequestLimit: 36,
  loadMore: false,
  loadLabel: "Load more",
  limitMobile: false,
}

MediaGrid.propTypes = {
  className: PropTypes.string,
  placeholders: PropTypes.number,
  itemRequestLimit: PropTypes.number,
  loadMore: PropTypes.bool,
  loadLabel: PropTypes.string,
  beforeList: PropTypes.node,
  afterList: PropTypes.node,
  limitMobile: PropTypes.bool,
  trackPrefix: PropTypes.string,
}

export default MediaGrid
