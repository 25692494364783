import React, { forwardRef } from "react"
import PropTypes from "prop-types"

import { classnames } from "../../utils/helpers"

import styles from "./card.module.scss"

const Card = forwardRef(function Card(
  {
    children,
    className,
    dotColor = "light",
    dotSize = null,
    dots = true,
    hover = false,
    href = null,
    pattern = "hashes",
    preventFocus = false,
    tag = "div",
    light = false,
    overflow = false,
    ...props
  },
  ref
) {
  const Tag = tag

  const renderCard = () => {
    return (
      <Tag
        className={classnames([
          className,
          styles.Card,
          hover ? styles["Card--hover"] : "",
          light ? styles["Card--light"] : "",
          overflow ? styles["Card--allow-overflow"] : "",
          pattern ? `pattern--${pattern}` : "",
        ])}
        ref={ref}
        {...(preventFocus ? { tabIndex: -1 } : null)}
        {...props}
      >
        {dots && (
          <>
            <div
              className={classnames([
                styles.Card__dots,
                styles["Card__dots--top"],
                styles[`Card__dots--${dotColor}`],
                dotSize ? styles[`Card__dots--${dotSize}`] : "",
              ])}
            ></div>
            <div
              className={classnames([
                styles.Card__dots,
                styles["Card__dots--bottom"],
                styles[`Card__dots--${dotColor}`],
                dotSize ? styles[`Card__dots--${dotSize}`] : "",
              ])}
            ></div>
          </>
        )}
        {children}
      </Tag>
    )
  }

  if (href) {
    return (
      <a href={href} target="_blank" rel="noopener nofollow noreferrer">
        {renderCard()}
      </a>
    )
  } else {
    return <>{renderCard()}</>
  }
})

Card.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  dotColor: PropTypes.string,
  dotSize: PropTypes.string,
  dots: PropTypes.bool,
  hover: PropTypes.bool,
  href: PropTypes.string,
  pattern: PropTypes.string,
  preventFocus: PropTypes.bool,
  tag: PropTypes.string,
  light: PropTypes.bool,
  overflow: PropTypes.bool,
}

export default Card
