import React, { useEffect, forwardRef } from "react"
import PropTypes from "prop-types"
import { Link } from "@reach/router"
import { withPrefix } from "gatsby"

import Card from "../card/card"
import Skeleton from "../skeleton/skeleton"

import useTracking from "../../hooks/use-tracking"
import useLocale from "../../hooks/use-locale"

import { classnames, formatDate } from "../../utils/helpers"

import styles from "./media-card.module.scss"

const MediaCard = forwardRef(function MediaCard(
  {
    hasData = false,
    href = null,
    slug = null,
    trackPrefix = null,
    image = null,
    title = null,
    date = null,
    stacked = true,
    className,
    ...props
  },
  ref
) {
  const imgElement = React.useRef()
  const { trackLink } = useTracking()
  const { isoLangKey } = useLocale()
  const dateObj = new Date(date)
  const sr_date = dateObj.toLocaleDateString(
    `${isoLangKey.split("-")[0]}-${isoLangKey.split("-")[1].toUpperCase()}`,
    {
      month: "short",
      day: "numeric",
      year: "numeric",
    }
  )

  useEffect(() => {
    if (hasData && image !== null) {
      imgElement.current.addEventListener(
        "load",
        () => {
          if (imgElement.current)
            imgElement.current.classList.add(styles["loaded"])
        },
        { once: true }
      )
      imgElement.current.src = image
    }
  }, [image, hasData])

  const renderCard = () => {
    return (
      <Card
        className={classnames([
          styles.MediaCard,
          stacked ? styles["MediaCard--stacked"] : "",
          className,
        ])}
        dots={false}
        pattern="hashes"
        hover={true}
        {...props}
      >
        {hasData ? (
          <>
            <div className={styles.MediaCard__thumb}>
              <img src="" alt="" ref={imgElement} loading="lazy" />
            </div>
            <span className="h6" aria-hidden="true">
              {formatDate(dateObj)}
            </span>
            <span className="visually-hidden">{sr_date}</span>
            <h2 className="h5">{title}</h2>
          </>
        ) : (
          <Skeleton />
        )}
      </Card>
    )
  }

  if (href) {
    return (
      <Link
        to={withPrefix(href)}
        ref={ref}
        onClick={event => {
          trackLink(
            {
              eVars: {
                37: `${trackPrefix ? trackPrefix + ": " : ""}article: ${slug}`,
              },
              events: [53],
            },
            event
          )
        }}
      >
        {renderCard()}
      </Link>
    )
  } else {
    return <>{renderCard()}</>
  }
})

MediaCard.propTypes = {
  children: PropTypes.any,
  href: PropTypes.string,
  skeleton: PropTypes.bool,
  stacked: PropTypes.bool,
  className: PropTypes.string,
  hasData: PropTypes.bool,
  slug: PropTypes.string,
  trackPrefix: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
}

export default MediaCard
